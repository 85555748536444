import * as React from 'react';
import {
  motion,
} from 'framer-motion';

import {
  Lang,
  Breakpoint,

  useDimensions,

  TextBlock,
} from '@bluecurrent/web-component-lib';

import Setup from '../../../config/Setup.config';

import handleLocalUrlChange from '../../../functions/handleLocalUrlChange';
import handleHeadingPrioritization from '../../../functions/handleHeadingPrioritization';

import Arrow from './Arrow';

export default function TextBlockComponent(props) {
  const { i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? Setup.FALLBACK_LANG : i18n.language;
  const hasBlueprint = props.params.blueprint !== 'single';

  const [position, setPosition] = React.useState('');

  const getPosition = () => {
    if (size.width < Breakpoint.sm && props.data.align !== undefined) return props.data.align;
    if (hasBlueprint && props.params.mirror) return 'right';
    if (hasBlueprint) return 'left';
    return 'center';
  };

  React.useEffect(() => {
    setPosition(getPosition());
  }, []);

  return (
    <motion.div
      style={{
        height: '100%',
        minHeight: props.params?.index === 0 ? '30vh' : 'auto',
        width: '100%',
        position: 'relative',
        paddingTop: props.data?.arrow ? 100 : 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
      }}
      transition={{
        duration: 0.5,
        ease: 'easeInOut',
        stiffness: 100,
      }}
      viewport={{
        once: true,
      }}
    >
      {
        (props.data.arrow && position === 'left') && (
          <Arrow
            align="left"
            text={props.data.arrow[lang]}
            size={size}
          />
        )
      }
      <motion.div
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        transition={{
          duration: 0.5,
          ease: 'easeInOut',
          stiffness: 100,
        }}
        viewport={{
          once: true,
        }}
        style={{
          marginTop: props.data.arrow
            ? 0
            : (size.width < Breakpoint.sm)
              ? -15
              : 30,
          paddingBottom: 30,
        }}
      >
        {
          (props.data.title !== undefined || props.data.text !== undefined) && (
            <TextBlock
              title={{
                content: props.data.title !== undefined && (
                  props.data.title[lang]
                ),
                portableText: props.data.title !== undefined,
                fontSize: (props.params.length === props.params.index + 1) ? '2em' : undefined,
                heading: handleHeadingPrioritization(props.params.index),
              }}
              text={{
                content: props.data.text !== undefined && (
                  props.data.text[lang]
                ),
                portableText: props.data.text !== undefined,
              }}
              align={
                size.width < Breakpoint.sm
                  ? props.data.align
                  : hasBlueprint ? 'left' : 'center'
              }
              buttons={
                props.data.buttons !== undefined
                && props.data.buttons.map((item) => ({
                  /* eslint-disable no-underscore-dangle */
                  id: item._key,
                  text: item.link.text === 'custom' ? item.link.custom_text[lang] : item.link.text[lang],
                  colorScheme: item.colorscheme,
                  onClick: () => window.open(handleLocalUrlChange(item.link.url), item.link.target),
                }))
              }
            />
          )
        }
      </motion.div>
      {
        (props.data.arrow && (position === 'right' || position === 'center')) && (
          <Arrow
            align="right"
            text={props.data.arrow[lang]}
            size={size}
          />
        )
      }
    </motion.div>
  );
}
