import * as React from 'react';

import {
  Breakpoint,

  useDimensions,

  Text,
  Arrow,
} from '@bluecurrent/web-component-lib';

export default function ArrowComponent(props) {
  const size = useDimensions();

  return (
    <div
      style={{
        position: 'relative',
        height: 'auto',
      }}
    >
      <div
        style={{
          height: 'auto',
          position: 'absolute',
          left: props.align === 'left' ? 'inherit' : 0,
          right: props.align === 'right' ? 'inherit' : 15,
          bottom: props.align === 'right' ? -120 : -35,
          maxWidth: size.width < Breakpoint.sm ? '100%' : '50%',
          display: 'flex',
          flexDirection: props.align === 'right' ? 'column-reverse' : 'column',
        }}
      >
        <Text
          fontFamily="Caveat"
          fontSize="2.3em"
          fontWeight={300}
          textAlign={props.align === 'left' ? 'right' : 'left'}
          lineHeight={0.9}
        >
          {props.text}
        </Text>
        <div
          style={{
            marginTop: props.align === 'right' ? 0 : -25,
            marginBottom: props.align === 'left' ? 0 : -25,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: props.align === 'right' ? 'flex-start' : 'flex-end',
          }}
        >
          <Arrow
            type="Curved"
            transform={
              `
              scaleX(${props.align === 'right' ? '-1' : '1'})
              scaleY(${props.align === 'right' ? '-1' : '1'})
              `
            }
            height={100}
            width={100}
          />
        </div>
      </div>
    </div>
  );
}
